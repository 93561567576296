import React from "react";
import Base from "../../Common/component/Base.js";
import TopHeader from "../../Header/component/TopHeader.js";
import Footer from "../../Footer/component/Footer.js";
import Content from "./Content";
import { Helmet } from "react-helmet";
import StickyBanner from "../../ritual/components/banners/StickyBanner";

export class Media extends Base {
  render() {
    return (
      <div id="media">
        <Helmet>
          <meta
            name="description"
            content="Power of Two and its founder Dr. Susan Heitler are often mentioned in national media as experts on marriage counseling and relationships. Learn more here."
          />
          <title>Power of Two : Media : Power of Two Marriage</title>
        </Helmet>

        <TopHeader />
        <Content />
        <Footer />
        <StickyBanner />
      </div>
    );
  }
}

export default Media;
