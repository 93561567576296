import React from "react";
import { Helmet } from "react-helmet";
import Home from "./Home/component/Home";
import About from "./About/component/About";
import Faq from "./Faq/component/Faq";
import Newsletter from "./Newsletter/component/Newsletter";
import NotFound from "./Main/component/NotFound";
import { LAUNCH_DARKLY_ID } from "./ritual/utils/config";

import { Route, Switch, useLocation } from "react-router-dom";
import {
  RedirectFromSignUp,
  RedirectFromLanding,
  RedirectFromPricing,
  RedirectFromPrivacyPolicy,
  RedirectFromTermsOfUse,
  RedirectFromLogin,
} from "./ritual/components/redirects/Redirects";
import pagesData from "./data/json_data.json";
import Info from "./Info/Info";
import Coach from "./Coach/Coach";
import Media from "./Media/component/Media";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import useAddButtonId from "./hooks/useAddButtonId";

function App() {
  const gtm = () => {
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.name='gtm';j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-K46RH6J');`;
  };

  useAddButtonId();

  return (
    <>
      <Helmet>
        <title>
          Marriage Counseling-Online alternative | more private, more affordable
          : Power of Two Marriage
        </title>
        <meta charSet="utf-8" />
        <meta name="description" content="Default" />
        <meta
          name="viewport"
          content="user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1, minimal-ui"
        />

        <meta property="og:title" content="Power of Two Marriage" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.poweroftwomarriage.com" />
        <meta property="og:image" content="/static/img/Macbook-Price.png" />
        <meta property="og:site_name" content="Power of Two Marriage" />
        <meta property="fb:admins" content="1008012311" />
        <meta
          property="og:description"
          content="You can fight less and communicate better with the help of psychologist & author, Susan Heitler’s new alternative to marriage counseling-online. Do it on your own, or with your partner."
        />

        {/* <script type="text/javascript" src="/api/v1/gtm/" /> */}
        <script type="text/javascript" name="gtm" id="gtm">
          {gtm()}
        </script>
      </Helmet>

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/media/*" component={Media} />
        <Route path="/landing/intro/*" component={RedirectFromLanding} />
        <Route exact path="/login/*" component={RedirectFromLogin} />
        <Route exact path="/signup/*" component={RedirectFromSignUp} />
        <Route exact path="/pricing" component={RedirectFromPricing} />
        <Route exact path="/faq/*" component={Faq} />
        <Route exact path="/newsletter" component={Newsletter} />
        <Route
          exact
          path="/privacy-policy/*"
          component={RedirectFromPrivacyPolicy}
        />
        <Route
          exact
          path="/terms-of-use/*"
          component={RedirectFromTermsOfUse}
        />

        {pagesData.data.map((page) => {
          return (
            <Route exact key={page.id} path={page.route} component={Info} />
          );
        })}
        <Route exact path="/coach" component={Coach} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}

export default withLDProvider({ clientSideID: LAUNCH_DARKLY_ID })(App);
