import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/component/Footer";
import TopHeader from "../Header/component/TopHeader";
import "./Info.styles.scss";
import pagesData from "../data/json_data.json";
import InfoLeft from "./InfoLeft";
import InfoRight from "./InfoRight";
import StickyBanner from "../ritual/components/banners/StickyBanner";

const Info = () => {
  const location = useLocation();

  const slug = location.pathname.split("/")[2]
    ? location.pathname.split("/")[2]
    : location.pathname.split("/")[1];

  const data = pagesData.data.find((page) => page.slug === slug);

  return (
    <div id="home">
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={data.meta_description} />
        <meta name="keywords" content={data.meta_keywords} />
      </Helmet>

      <TopHeader />

      <div className="info-container">
        <InfoLeft data={data} />
        <InfoRight data={data} />
      </div>
      <div className="info-bottom">
        <img src="/static/info_bottom.gif" alt="Info Bottom Gif" />
        <p>
          <strong>
            Funding for this project was provided by the United States
            Department of Health Services, Administration for Children and
            Families, Grant 90-FE-0123.
          </strong>
          Any opinions, finding, and conclusions or recommendations expressed in
          this material are those of the author(s) and do not necessarily
          reflect the views of the United States Department of Health and Human
          Servies, Administration for Children and Families.
        </p>
      </div>
      <Footer />
      <StickyBanner />
    </div>
  );
};

export default Info;
