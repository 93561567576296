import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SatisfactionGuarantee } from "../../Common/component/SatisfactionGuarantee.js";
import RitualBanner from "../../ritual/components/banners/LandingBanner";

export class Content extends Component {
  state = {
    slideNumber: 0,
    timer: null,
    showModal: false,
  };

  componentDidMount() {
    this.startCarousel();
  }

  startTimer() {
    this.setState({
      timer: window.setTimeout(this.startCarousel, 3000),
    });
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer);

    this.setState({
      timer: null,
    });
  }

  startCarousel = () => {
    const slides = document.querySelectorAll(".slider .member");
    const slidesNavElements = document.querySelectorAll(".slider-nav li");

    slides.forEach((item) => (item.style.display = "none"));

    slidesNavElements.forEach((item) => item.classList.remove("active"));

    slides[this.state.slideNumber].style.display = "block";
    slidesNavElements[this.state.slideNumber].classList.add("active");

    const nextSlideNumber =
      this.state.slideNumber >= slides.length - 1
        ? 0
        : this.state.slideNumber + 1;

    this.setState(
      {
        slideNumber: nextSlideNumber,
      },
      this.startTimer,
    );
  };

  openSatisfactionGuarantee = () => {
    this.setState({
      showModal: true,
    });
  };

  onClose = () => {
    this.setState({
      showModal: false,
    });
  };

  switchVideo = (e) => {
    const iframe = document.createElement("iframe");
    iframe.className = "sproutvideo-player";
    iframe.type = "text/html";
    iframe.src =
      "https://videos.sproutvideo.com/embed/7098d2b5171be2c1f8/d5f0f252eba19d09?type=sd&autoPlay=true&loop=true&transparent=true";
    iframe.width = "100%";
    iframe.height = "275";
    iframe.frameborder = "0";
    iframe.allowfullscreen = "true";

    e.target.replaceWith(iframe);
  };

  render() {
    return (
      <>
        <RitualBanner />
        <div className="home-top-section section">
          <div className="section-wrapper">
            <div className="home-top-section-left">
              <div
                className="video"
                id="intro-video"
                onClick={this.switchVideo}
              >
                <img src="/static/img/intro_video.jpg" alt="intro_video" />
              </div>
            </div>
            <div className="home-top-section-right">
              <h1>Talk with your relationship coach today.</h1>
              <h3>The online alternative to marriage counseling.</h3>
              <div className="home-top-section-right-bottom">
                <div className="relationship-quiz">
                  <Link
                    to="/signup/"
                    className="relationship-quiz-button button button-large"
                  >
                    GET STARTED NOW
                  </Link>
                </div>

                <div className="launch-quiz">
                  <Link className="launch-quiz-link" to="/landing/intro/">
                    Take our relationship quiz ⇛
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-how-it-works-section section">
          <div className="section-wrapper">
            <div className="container">
              <p className="title text-center">
                <strong>How does it work?</strong>
              </p>
              <div className="home-how-it-works-description text-center">
                <div className="home-how-it-works-description-text">
                  <h2>
                    Personalized, affordable, confidential,
                    <br /> interactive and always available.
                  </h2>
                  <p>
                    Join on your own or as a couple. No need to wait for an
                    appointment.
                    <br />
                    Your personal coach will recommend activities, answer
                    questions and encourage you to succeed. <br />
                    All this fun and interactivity is really affordable for less
                    than a dollar a day* and we offer a{" "}
                    <strong
                      style={{ cursor: "pointer", color: "#009AD9" }}
                      onClick={this.openSatisfactionGuarantee}
                    >
                      satisfaction guarantee
                    </strong>
                    .
                  </p>
                  {this.state.showModal && (
                    <SatisfactionGuarantee onClose={this.onClose} />
                  )}
                </div>
                <Link
                  to="/signup/"
                  className="relationship-quiz-button button button-large"
                >
                  Sign Up Today
                </Link>
                <br />
                <br />
                <img
                  alt="howitworks"
                  className="how-it-works-img"
                  src="/static/img/howitworks.jpg"
                />
                <p>*Requires annual plan</p>
              </div>
            </div>
          </div>
        </div>

        <div className="home-testimonial-section section">
          <div className="section-wrapper">
            <div className="container">
              <p className="title text-center">
                <strong>What our members say?</strong>
              </p>
              <div className="member">
                <div className="profile">
                  <img
                    width="128"
                    height="128"
                    alt="member"
                    src="/static/img/member1.png"
                  />
                  <span>Samantha</span>
                </div>
                <div className="quote">
                  <p>
                    Just 30 minutes of watching, participating, and listening to
                    the various activities/videos gave us more tools than we had
                    in all of our counseling sessions combined. This site is a
                    godsend.
                  </p>
                </div>
              </div>
              <div className="member">
                <div className="profile">
                  <img
                    width="128"
                    height="128"
                    alt="member"
                    src="/static/img/member2.png"
                  />
                  <span>Andy</span>
                </div>
                <div className="quote">
                  <p>
                    We learned how much anger can hurt a relationship and how
                    destructive it can be. Power of Two helped us create an exit
                    and reconnecting strategy. This is something we needed to
                    do.
                  </p>
                </div>
              </div>
              <p className="text-center">
                <Link
                  to="/signup/"
                  className="relationship-quiz-button button button-large"
                >
                  Get Started Now
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="home-founder-section gray section">
          <div className="section-wrapper">
            <div className="container">
              <p className="title text-center">
                <strong>Power of two founder</strong>
              </p>
              <div className="home-founder-section-left">
                <img alt="founder" src="/static/img/founder.jpg" />
              </div>
              <div className="home-founder-section-right">
                <p>
                  Dr. Susan Heitler specializes in teaching couples the skills
                  they need to enjoy a strong, long-lasting and loving
                  partnership. A clinical psychologist in private practice in
                  Denver for over 30 years, Dr. Heitler is a graduate of Harvard
                  University with a doctorate from New York University.
                </p>
                <p>
                  Dr. Heitler's books{" "}
                  <strong>
                    <a
                      href="http://www.amazon.com/Power-Two-Secrets-Strong-Marriage/dp/1572240598?ie=UTF8&amp;keywords=power%20of%20two%20dr.%20heitler&amp;qid=1462300056&amp;ref_=sr_1_fkmr0_2&amp;sr=8-2-fkmr0"
                      target="_blank"
                    >
                      The Power of Two
                    </a>
                  </strong>
                  ,{" "}
                  <strong>
                    <a
                      href="http://www.amazon.com/Power-Two-Workbook-Communication-Marriage/dp/1572243341?ie=UTF8&amp;keywords=power%20of%20two%20dr.%20heitler&amp;qid=1462300056&amp;ref_=sr_1_fkmr0_1&amp;sr=8-1-fkmr0"
                      target="_blank"
                    >
                      The Power of Two Workbook
                    </a>
                  </strong>{" "}
                  co-authored with her daughter Dr. Abigail Hirsch, and her book
                  on therapy{" "}
                  <strong>
                    <a
                      href="http://www.amazon.com/Conflict-Resolution-Strategies-Individuals-Couples/dp/0393310930?ie=UTF8&amp;keywords=Susan%20Heitler&amp;qid=1462316963&amp;ref_=sr_1_4&amp;sr=8-4"
                      target="_blank"
                    >
                      From Conflict to Resolution
                    </a>
                  </strong>{" "}
                  form the basis for the Power Of Two Marriage program. Dr.
                  Heitler's newest book,{" "}
                  <strong>
                    <a
                      href="http://www.amazon.com/Prescriptions-Without-Pills-Depression-Anxiety/dp/1630478105/ref=sr_1_1?ie=UTF8&amp;qid=1462300152&amp;sr=8-1&amp;keywords=prescriptions+without+pills+book"
                      target="_blank"
                    >
                      Prescriptions Without Pills: For Relief from Depression,
                      Anger, Anxiety, and More
                    </a>
                  </strong>{" "}
                  offers relief from the common colds of mental health.
                </p>
                <p>
                  Dr. Heitler lives what she teaches. She and her husband have
                  been happily married for nearly 40 years, raising four
                  children, now with children of their own.
                </p>
              </div>
              <div className="home-founder-section-featured">
                <strong>
                  Dr. Heitler regularly contributes to{" "}
                  <a href="http://psychologytoday.com/blog/resolution-not-conflict">
                    Psychology Today.com
                  </a>{" "}
                  and other media, including:
                </strong>
                <div className="home-founder-section-featured-articles">
                  <a href="/media#articles">
                    <img alt="featured" src="/static/img/featured.jpg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-professionals-section section">
          <div className="container">
            <p className="title text-center">
              <strong>What are professionals saying?</strong>
            </p>

            <div className="slider">
              <ul>
                <li className="member">
                  <div className="profile">
                    <img
                      width="128"
                      height="127"
                      alt="member"
                      src="/static/img/pros-harry.jpg"
                    />
                    <span>
                      Harry Smith <br />
                      The Early Show <br />
                      Co-anchor on CBS
                    </span>
                  </div>
                  <div className="quote">
                    <p>
                      Susan Heitler's effect on my life was profound and
                      permanent... She just really helped me put my life back
                      together.
                    </p>
                  </div>
                </li>
                <li className="member">
                  <div className="profile">
                    <img
                      width="128"
                      height="128"
                      alt="member"
                      src="/static/img/pro-Marie-McKinney-Oates.jpg"
                    />
                    <span>
                      Marie McKinney-Oates <br />
                      Nashville Marriage Studio
                    </span>
                  </div>
                  <div className="quote">
                    <p>
                      The activities were not only educational but really
                      entertaining and well designed. A HUGE and surprising
                      plus.
                    </p>
                  </div>
                </li>
                <li className="member">
                  <div className="profile">
                    <img
                      width="128"
                      height="128"
                      alt="member"
                      src="/static/img/pros-jenny.jpg"
                    />
                    <span>
                      Jenny <br />
                      The Mommy Brand
                    </span>
                  </div>
                  <div className="quote">
                    <p>
                      I started the program and found that I had a lot to learn
                      about relationships! My overall review: TWO THUMBS UP!
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="slider-nav">
              <ul>
                <li className="active">1</li>
                <li>2</li>
                <li>3</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="home-quiz-section section">
          <div className="container">
            <p className="title text-center">
              <strong>Try our free relationship quiz</strong>
            </p>

            <h2>Take the Power of Two Relationship Quiz.</h2>
            <p>
              See how your relationship measures up and find out what areas
              could be improved!
            </p>
            <Link to="/landing/intro/">
              <img width="400" src="/static/img/quizGraphic.png" />
            </Link>
            <p className="center">
              <Link
                to="/landing/intro/"
                className="relationship-quiz-button button button-large"
              >
                Begin the Relationship Quiz
              </Link>
            </p>
          </div>
        </div>

        <div className="home-articles-section gray section">
          <div className="section-wrapper">
            <div className="container">
              <p className="title text-center">
                <strong>
                  More from the best alternative to marriage counseling online.
                </strong>
              </p>

              <div className="articles">
                <div className="article">
                  <ul className="targets">
                    <li>
                      Tools for{" "}
                      <a href="/info/dealing-with-infidelity/">
                        dealing with infidelity
                      </a>
                      .
                    </li>
                    <li>
                      Learn{" "}
                      <a href="/info/how-to-communicate-in-a-relationship/">
                        how to communicate in a relationship
                      </a>
                      --the key to a great marriage.
                    </li>
                    <li>
                      It's time to learn{" "}
                      <a href="/info/how-to-communicate-with-your-spouse/">
                        how to communicate with your spouse
                      </a>
                      .
                    </li>
                    <li>
                      The{" "}
                      <a href="/info/how-to-fix-a-relationship/">
                        How to Fix a Relationship
                      </a>{" "}
                      Roadmap.
                    </li>
                    <li>
                      Learn the secrets to{" "}
                      <a href="/info/how-to-rekindle-a-marriage/">
                        how to rekindle a marriage
                      </a>
                      .
                    </li>
                    <li>
                      Got <a href="/info/marital-problems/">marital problems</a>
                      ? Find out how to solve each one with simple steps.
                    </li>
                    <li>
                      5 tips for finding good{" "}
                      <a href="/info/marriage-help-books/">
                        marriage help books.
                      </a>
                    </li>
                    <li>
                      Identifying{" "}
                      <a href="/info/marriage-problems/">marriage problems</a>?
                      Find out how to fix them.
                    </li>
                    <li>
                      Have you ever wondered how{" "}
                      <a href="/info/online-marriage-counseling/">
                        online marriage counseling
                      </a>{" "}
                      works?
                    </li>
                    <li>
                      <a href="/info/online-relationship-counseling/">
                        Online relationship counseling
                      </a>{" "}
                      – Get help the way you want it.
                    </li>
                    <li>
                      Find{" "}
                      <a href="/info/sexless-marriage-help/">
                        sexless marriage help
                      </a>{" "}
                      here.
                    </li>
                    <li>
                      What the experts know about
                      <a href="/info/surviving-an-affair/">
                        {" "}
                        surviving an affair.
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Content;
