import React from "react";
import TopHeader from "../../Header/component/TopHeader.js";
import Content from "./Content";
import { Content as ContentContainer } from "../../Main/container/Content";
import Footer from "../../Footer/component/Footer.js";
import { Helmet } from "react-helmet";
import Base from "../../Common/component/Base.js";
import StickyBanner from "../../ritual/components/banners/StickyBanner";

export class Newsletter extends Base {
  render() {
    return (
      <div id="newsletter">
        <Helmet>
          <title>Newsletter : Power of Two Marriage</title>
          <meta name="description" content="" />
        </Helmet>
        <TopHeader />
        <ContentContainer component={Content} />
        <Footer />
        <StickyBanner />
      </div>
    );
  }
}

export default Newsletter;
