import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <div id="footer_wrap">
          <div id="info-menu">
            <ul className="hnav">
              <li>
                <a href="/help/">Questions?</a> |{" "}
              </li>
              <li>
                <a href="mailto:support@heyritual.com">
                  info@poweroftwomarriage.com
                </a>{" "}
                |{" "}
              </li>
              <li>
                <Link to="/about">About</Link> |{" "}
              </li>
              <li>
                <Link to="/faq/">FAQ</Link> |{" "}
              </li>
              <li>
                <a href="/about#contact">Contact us</a> |{" "}
              </li>
              <li>
                <a href="/privacy-policy/">Privacy Policy</a> |{" "}
              </li>
              {/*<li><a href="/media/">Media</a> | </li>*/}
              <li>
                <a href="/terms-of-use/">Terms of Use</a> |{" "}
              </li>
              <li>
                <a href="https://blog.heyritual.com/">Blog</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
